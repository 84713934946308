import Vue from 'vue';
import App from './App.vue';

import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'

Vue.directive('tooltip', VTooltip)
Vue.directive('close-popover', VClosePopover)
Vue.component('v-popover', VPopover)


// Import $tera / @iebh/Tera-fy global service
// NOTE: See bottom of file inside main async() init loop for when TeraFy actually boots
import TeraFy from '@iebh/tera-fy/dist/terafy.es2019.js'; // FIX: Use annoyingly old and specific version as Babel struggles with ESNEXT class imports
import TerafyVue from '@iebh/tera-fy/dist/plugin.vue2.es2019.js';
import {defaultPolyglotState} from './defaultPolyglotState.js';

let terafy = new TeraFy()
	.set("devMode", process.env.VUE_APP_TERAFY_DEV)
	.setIfDev("verbosity", process.env.VUE_APP_TERAFY_VERBOSITY)
	.use(TerafyVue); // Add the Vue plugin


Vue.config.productionTip = false;

// Boot Vue + Tera
(async ()=> {
	const app = new Vue({
		render: h => h(App)
	});

	// Boot teraFy + require project + pull & subscribe to project data
	await terafy.init({
		app, // Provide app to bind against
		Vue, // Provide the vue version to use
	});

	await terafy.setProjectStateDefaults(defaultPolyglotState);

	app.$mount("#app");
})();
