let defaultPolyglotState={
    polyglot:{
        searchString:{
			Query:""
		},
        engines: [
			{
				key: "PubMed abbreviation",
				label: "PubMed",
                manualVersion: "", //manual SearchString version
				polyglotVersion: ""
			},
			{
				key: "Ovid MEDLINE",
				label: "Ovid MEDLINE",
                manualVersion: "",
				polyglotVersion: ""
			},
			{
				key: "Cochrane Library",
				label: "Cochrane Library",
                manualVersion: "",
				polyglotVersion: ""
			},
			{
				key: "Embase (Elsevier)",
				label: "Embase (Elsevier)",
                manualVersion: "",
				polyglotVersion: ""
			},
			{
				key: "Ovid MEDLINE",
				label: "Embase (Ovid)",
                manualVersion: "",
				polyglotVersion: ""
			},
			{
				key: "CINAHL (Ebsco)",
				label: "CINAHL (Ebsco)",
                manualVersion: "",
				polyglotVersion: ""
			},
			{
				key: "Web of Science",
				label: "Web of Science",
                manualVersion: "",
				polyglotVersion: ""
			},
			{
				key: "WoS Advanced",
				label: "WoS Advanced",
                manualVersion: "",
				polyglotVersion: ""
			},
			{
				key: "Scopus (basic search)",
				label: "Scopus (basic search)",
                manualVersion: "",
				polyglotVersion: ""
			},
			{
				key: "Scopus (advanced search)",
				label: "Scopus (advanced search)",
                manualVersion: "",
				polyglotVersion: ""
			},
			{
				key: "PsycInfo (Ovid)",
				label: "PsycInfo (Ovid)",
                manualVersion: "",
				polyglotVersion: ""
			},
			{
				key: "PsycInfo (Ebsco)",
				label: "PsycInfo (Ebsco)",
                manualVersion: "",
				polyglotVersion: ""
			},
			{
				key: "International HTA Database",
				label:"International HTA Database",
                manualVersion: "",
				polyglotVersion: ""
			},
			{
				key: "ProQuest Health and Medical",
				label: "ProQuest",
                manualVersion: "",
				polyglotVersion: ""
			},
			{
				key: "SPORTDiscus",
				label: "SPORTDiscus",
                manualVersion: "",
				polyglotVersion: ""
			},
			{
				key: "PubMed full",
				label: "PubMed Expanded",
                manualVersion: "",
				polyglotVersion: ""
			},
			{
				key: "Business Source Ultimate",
				label:"Business Source Ultimate",
                manualVersion: "",
				polyglotVersion: ""
			},
			{
				key: "lexicalTreeJSON",
				label: "lexicalTreeJSON",
                manualVersion: "",
				polyglotVersion: ""
			}
	
			
		],
    }
}
export {defaultPolyglotState}